
















































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class VersionHistory extends Vue {
  expanded = [0];
}
